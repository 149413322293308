<template>
  <div class="verify-id-inputs box-shadow mt-5 lg:m-2.5 sm:m-2.5">
    <vx-card
      :class="
        page_type === 'paylater' && showLogin
          ? 'login-block rounded-bl-none rounded-br-none'
          : 'hiddenDiv rounded-bl-none rounded-br-none'
      "
    >
      <h3 class="text-xl font-medium text-center" style="color:#666666">Log in to your account</h3>
    </vx-card>
    <vx-card
      :class="
        page_type === 'paylater' && showLogin
          ? 'lg:m-2.5 login-form sm:m-2.5 rounded-tl-none rounded-tr-none'
          : 'hiddenDiv lg:m-2.5 sm:m-2.5 rounded-tl-none rounded-tr-none'
      "
    >
      <vs-row class="flex-col items-center justify-center" style="max-width:370px;"
>
        <div>
          <div class="error-message-container login-error" v-if="error">
            <vs-icon icon="error_outline" size="1.5rem"></vs-icon>
            <div class="ml-3 ">
              <span class="text-base font-medium text-text-blue">{{ error.title }}</span>
              <br />
              <p style="color: black" class="text-sm">
                {{ error.message }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <label class="w-full text-base font-light">Email</label>
          <div class="input-container persist-width">
            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
            <vs-input
              class="w-full custom-w"
              v-model="email"
              name="email"
              data-vv-validate-on="blur"
              v-validate="'required|email|min:3'"
            />
          </div>
        </div>
        <div
          class="mt-5"
        >
          <label class="w-full text-base font-light">Password</label>
          <div class="input-container persist-width">
            <span class="text-danger text-sm" v-show="errors.has('password')">{{
              errors.first("password")
            }}</span>
            <vs-input
              class="w-full custom-w"
              v-model="password"
              name="password"
              type="password"
              v-validate="'required'"
            />
          </div>
        </div>
        <div
          class="mt-2 w-full text-left"
        >
          <a :href="forgotUrl" target="_blank" class="text-sm">Forgot password?</a>
        </div>
        <div
          class="mt-5 w-full"
        >
          <vs-button

            class="w-full"
            v-round
            @click="login"
          >
            Log In
          </vs-button>
        </div>
        <div
          class="mt-5 w-full"
        >
          <vs-button

            v-round
            @click="cancel"
            class="w-full only-border-btn flex justify-center"
          >
            Cancel
          </vs-button>
        </div>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import { Validator } from "vee-validate";

const dict = {
  custom: {
    email: {
      required: "Please enter your email"
    }
  }
};
Validator.localize("en", dict);

export default {
  props: {
    formData: { type: Object },
    showLogin: { type: Boolean },
    page_type: { type: String },
    merchantId: { type: String },
    forgotPassword: { type: Function },
    loginCustomer: { type: Function },
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      forgotUrl: ""
    };
  },
  methods: {
    async login() {
      this.error = await this.loginCustomer(this.email, this.password);
    },
    cancel(){
      this.$emit("cancelLogin");
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    }
  },
  mounted(){
    this.forgotUrl = process.env.VUE_APP_CUSTOMER_PORTAL_URL+this.merchantId+"/customer-login"
  }
};
</script>